import { type FC, useEffect, useMemo } from 'react';
import {
    createBrowserRouter,
    createHashRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Outlet,
    useRouteError,
} from 'react-router-dom';
import { getResourcesRoutes } from 'Resources';
import { getTutorBotRoutes } from 'TutorBot';
import { Trans } from 'react-i18next';
import { useErrorLogService } from 'ErrorLogging';
import { getNominationsRoutes } from 'Nominations';
import { getChooseInstitutionRoutes } from 'Institutions/ChooseInstitution/Routes';
import { useEmitRouteChangeSuccessOnReactRouteChange, useRespondToAngularRouteChanges } from 'FrontRoyalAngular';

// show nothing. Let angular render the route
function NotFound() {
    return null;
}

function ErrorBoundary() {
    const ErrorLogService = useErrorLogService();
    const error = useRouteError() as Error;

    useEffect(() => {
        if (error) ErrorLogService.notify(error, undefined, {});
    }, [error, ErrorLogService]);

    return (
        <div className="flex h-2/6 w-full items-center justify-center">
            <h1 className="text-center text-lg font-semibold leading-relaxed text-grey-darkish">
                <Trans
                    i18nKey="front_royal_api_error_handler.api_error_handler.something_went_wrong_check_back_later"
                    components={{ br: <br /> }}
                />
            </h1>
        </div>
    );
}

// FIXME: this is potentially dangerous because it ends up being an import explosion, where any component
// that has to import this file is suddenly importing anything that is routed, so one day our entire app. This
// could lead to circular reference issues, for example.
const routeArray = createRoutesFromElements(
    <Route path="/" element={<Outlet />} ErrorBoundary={ErrorBoundary}>
        {getResourcesRoutes()}
        {getTutorBotRoutes()}
        {getNominationsRoutes()}
        {getChooseInstitutionRoutes()}
        <Route path="*" element={<NotFound />} />
    </Route>,
);

export const validTopLevelReactRoutes = routeArray.flatMap(r => r.children?.map(c => c.path));

const EmitRouteChangeSuccess: FC = function EmitRouteChangeSuccess() {
    useEmitRouteChangeSuccessOnReactRouteChange();
    return null;
};

export function FrontRoyalRouter() {
    const router = useMemo(() => {
        const routerFn = window.CORDOVA ? createHashRouter : createBrowserRouter;
        return routerFn([
            {
                path: '/',
                id: 'root',
                element: (
                    <>
                        <EmitRouteChangeSuccess />
                        <Outlet />
                    </>
                ),
                children: routeArray, // Assuming `routeArray` contains your child routes
            },
        ]);
    }, []);

    useRespondToAngularRouteChanges(router);

    return (
        <div className="h-full">
            <RouterProvider router={router} />
        </div>
    );
}

export default FrontRoyalRouter;
